const theme = {
	colors:{
		neutralDarkest: 'hsl(0, 0%, 10%)',
		neutralLightest: 'hsl(250deg 0% 100%)',
		neutral: 'hsl(250deg 0% 50%)',
		primary: 'hsl(203deg, 89%, 89%)',
		secondary: '#fcddca',
	},
	responsive:{
		media : {
			xs: '400px',
			sm: '600px',
			lg: '1025px',
			xl: '1440px',
		},
		whitespace: {
			xs: 10,
			sm: 15,
			lg: 25,
			xl: 25,
		},
		fontSize: {
			xs: '.8em',
			sm: '.8em',
			lg: '1.2em',
			xl: '1.4em',
		}
	},
	properties: {
		borderRadius: '0.4em',
		borderWidth: '0.25em'
	}
};

export default theme;
