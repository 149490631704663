import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import nl from './nl.json';
import en from './en.json';



export const resources = {
	en: { translation: en },
	nl: { translation: nl },
};

const options = {
	order: ['querystring', 'navigator'],
	lookupQuerystring: 'lng'
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: resources,
		detection: options,
		fallbackLng: 'en',
		supportedLngs: ['en','nl'],
		interpolation: {
			escapeValue: false,
		},
		debug: false,
	});

export default i18n;