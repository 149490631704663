import { User } from 'firebase/auth';
import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { auth } from '../../firebase/firebase';

const AuthContext = createContext<AuthContextProps>({
	currentUser: null
});

const AuthContextProvider  = ( props: {children: ReactNode} ) => {

	const [currentUser, setCurrentUser] = useState<User | null>(auth.currentUser);

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(user  => {
			setCurrentUser(user);
		});
		return unsubscribe;
	}, []);

	const value = {
		currentUser
	};		


	//#region render

	return (
		<AuthContext.Provider value={{...value}}>
			<>{props.children}</>
		</AuthContext.Provider>
	);

	//#endregion
};

// types

type AuthContextProps = {
	currentUser: User | null;
}

// exports

const useAuthContext = () => useContext(AuthContext);

export {AuthContextProvider, AuthContext, useAuthContext};
