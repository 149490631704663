import { createGlobalStyle } from 'styled-components';
import Theme from './theme';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const fonts = require('../assets/fonts/fonts.css');

const GlobalStyle = createGlobalStyle`

    ${fonts};

	html {
		height: -webkit-fill-available;
		min-height: 100%;
		height: 100%;	
		font-family: 'Quicksand', sans-serif;
		color: ${Theme.colors.neutralDarkest};
	}

	body {
		margin:0;
		padding:0;
		height: 100%;
        overflow: hidden;
		font-weight: 600;

		// Extra small screens
		@media (max-width: ${p => p.theme.responsive.media.sm}){
			font-size: ${p => p.theme.responsive.fontSize.xs};
		}

		// Small screens (tablets, big phones, small monitors).
		@media (min-width: ${p => p.theme.responsive.media.sm}){
			font-size: ${p => p.theme.responsive.fontSize.sm};
		}

		// Large screens
		@media (min-width: ${p => p.theme.responsive.media.lg}){
			font-size: ${p => p.theme.responsive.fontSize.lg};
		}

		// Extra large screens
		@media (min-width: ${p => p.theme.responsive.media.xl}){
			font-size: ${p => p.theme.responsive.fontSize.lg};
		}

	}

	* {
		box-sizing: border-box;
        user-select: none;
	}

	p {
		
		margin-top:0;
		font-weight: 600;

	}

	strong {
		font-weight: 900;
		font-size: 1.2em;
	}

	h1 {
		margin-top: 0;
		font-weight: 900;
		font-size: 3em;

	}

	button {
		
		// Extra small screens
		@media (max-width: ${p => p.theme.responsive.media.sm}){
			font-size: ${p => p.theme.responsive.fontSize};
		}

		// Small screens (tablets, big phones, small monitors).
		@media (min-width: ${p => p.theme.responsive.media.sm}){
			font-size: ${p => p.theme.responsive.fontSize};
		}

		// Large screens
		@media (min-width: ${p => p.theme.responsive.media.lg}){
			font-size: ${p => p.theme.responsive.fontSize};
		}

		// Extra large screens
		@media (min-width: ${p => p.theme.responsive.media.xl}){
			font-size: ${p => p.theme.responsive.fontSize};
		}

		font-weight: 600;
	}

	#root {
		height: 100%;
	}

	/* custom scroll bar */

	/* width */
	::-webkit-scrollbar {
		width: 7.5px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: ${Theme.colors.neutral};
	}
	
	/* Handle */
	::-webkit-scrollbar-thumb {
		background:  ${Theme.colors.neutralLightest}; 
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #555; 
	}

`;

export default GlobalStyle;
