import { signOut } from 'firebase/auth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase/firebase';

const Logout = () => {

	const navigate = useNavigate();

	useEffect(() => {
		signOut(auth).then(() => {
			sessionStorage.setItem('auth', '0');
			navigate('/login');}
		);
	}, []);

	return ( 
		<>
		</> 
	);
};
 
export default Logout;