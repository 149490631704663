import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Logout from './authentication/Logout';
import { AuthContextProvider } from './context/auth/AuthContext';
import CustomThemeProvider from './style/CustomThemeProvider';
import './locales/i18n';

// //
// Analytics
// //

import Plausible from 'plausible-tracker';

const { trackPageview } = Plausible({
	domain:'dieterendemo.tqwi.nl',
	hashMode: true,
});

// //
// Routing
// //

const Protected = lazy(() => import('./authentication/Protected'));
const Login = lazy(() => import('./authentication/Login'));
const Blacklist = lazy(() => import('./authentication/Blacklist'));

const router = createBrowserRouter([
	{
		path: '*',
		element: <Protected/>
	},
	{
		path: '/login',
		element: <Login/>
	},
	{
		path: '/logout',
		element: <Logout/>
	},
	{
		path: '/blacklist',
		element: <Blacklist/>
	}
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<CustomThemeProvider>
			<AuthContextProvider>
				<Suspense>
					<RouterProvider router={router}/>
				</Suspense>
			</AuthContextProvider>
		</CustomThemeProvider>
	</React.StrictMode>
);